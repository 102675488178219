<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
      class="py-2"
    >
      <b-col
        md="5"
        lg="8"
      >
        <!-- Page title -->
        <h2 class="mb-2 mb-md-0">
          Program Studi
        </h2>
      </b-col>
      <b-col
        md="7"
        lg="4"
      >
        <!-- Search -->
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchKeyword"
            placeholder="Cari Nama Program Studi"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card body-class="p-0">
      <!-- Header -->
      <b-card-header>
        <h4 class="mb-0">
          Daftar Program Studi
        </h4>
        <b-button
          v-b-modal.new-data
          variant="outline-primary"
        >
          <feather-icon icon="PlusIcon" />
          <span>Tambah</span>
        </b-button>
      </b-card-header>

      <!-- Body -->
      <overlay-loading :loading="fetchLoading">
        <b-table
          id="table-transition-gjmf"
          ref="depTable"
          :items="departments"
          :fields="fields"
          class="m-0"
          responsive="sm"
          show-empty
          primary-key="number"
          hover
          :tbody-transition-props="transProps"
        >
          <!-- No Data -->
          <template #empty>
            <p class="mb-0 py-2 text-center">
              Tidak ada data
            </p>
          </template>
          <template #cell(action)="data">
            <div class="d-flex align-items-center">
              <b-button
                v-b-modal.edit-data
                variant="success"
                class="action-button mr-1"
                @click="() => prepareUpdate(data)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>
              <b-button
                v-b-modal.delete-data
                variant="danger"
                class="action-button"
                @click="() => prepareDelete(data)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </overlay-loading>

      <!-- Footer -->
      <b-card-footer>
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            sm="2"
            class="mb-2 mb-sm-0"
          >
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            col
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>

    <!-- Delete department modal -->
    <b-modal
      id="delete-data"
      title="Hapus"
      cancel-variant="secondary"
      cancel-title="Tidak"
      ok-title="Iya"
      ok-variant="danger"
      body-class="py-2"
      footer-class="d-flex justify-content-center"
      centered
      @ok="() => onDelete()"
    >
      <b-card-text class="text-center">
        <h4 class="mb-0">
          Hapus data?
        </h4>
      </b-card-text>
    </b-modal>

    <!-- New department modal -->
    <b-modal
      id="new-data"
      title="Tambah Program Studi"
      body-class="py-2"
      hide-footer
      centered
    >
      <b-card-text>
        <validation-observer
          ref="newDepartmentForm"
        >
          <b-form @submit.prevent="onSubmit">
            <!-- Nama Program Studi -->
            <b-form-group
              label="Nama Program Studi"
              label-for="department-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Program Studi"
                vid="department-name"
                rules="required"
              >
                <b-form-input
                  id="department-name"
                  v-model="form.add.program_studi"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Jenjang -->
            <b-form-group
              label="Jenjang"
              label-for="jenjang"
            >
              <validation-provider
                #default="{ errors }"
                name="Jenjang"
                vid="jenjang"
                rules="required"
              >
                <v-select
                  id="jenjang"
                  v-model="form.add.lkps_program_id"
                  :reduce="program => program.id"
                  :options="form.programs"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Save Program Studi -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="newDepartmentLoading"
                v-text="newDepartmentLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- Edit department modal -->
    <b-modal
      id="edit-data"
      title="Ubah Program Studi"
      body-class="py-2"
      hide-footer
      centered
    >
      <b-card-text>
        <validation-observer
          ref="editDepartmentForm"
        >
          <b-form @submit.prevent="onUpdate">
            <!-- Nama Program Studi -->
            <b-form-group
              label="Nama Program Studi"
              label-for="department-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama Program Studi"
                vid="department-name"
                rules="required"
              >
                <b-form-input
                  id="department-name"
                  v-model="form.edit.program_studi"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Jenjang -->
            <b-form-group
              label="Jenjang"
              label-for="jenjang"
            >
              <validation-provider
                #default="{ errors }"
                name="Jenjang"
                vid="jenjang"
                rules="required"
              >
                <v-select
                  id="jenjang"
                  v-model="form.edit.lkps_program_id"
                  :reduce="program => program.id"
                  :options="form.programs"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Save Program Studi -->
            <div class="text-right">
              <b-button
                type="submit"
                variant="primary"
                :disabled="editDepartmentLoading"
                v-text="editDepartmentLoading ? 'Menyimpan...' : 'Simpan'"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BFormInput,
  BButton,
  BPagination,
  BFormSelect,
  BModal,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

import useJwt from '@/auth/jwt/useJwt'
import OverlayLoading from '@/views/components/OverlayLoading.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BModal,
    ValidationObserver,
    ValidationProvider,
    VSelect,
    OverlayLoading,
  },
  data() {
    return {
      transProps: {
        name: 'flip-list',
      },
      // Form
      form: {
        add: {
          program_studi: '',
          lkps_program_id: 1,
        },
        edit: {
          id: 0,
          program_studi: '',
          lkps_program_id: 1,
        },
        deleteId: 0,
        // Jenjang
        programs: [
          { id: 1, label: 'Sarjana (S)' },
          { id: 2, label: 'Magister (M)' },
          { id: 3, label: 'Doktor (D)' },
        ],
      },

      // Table fields, data, pagination
      fields: [
        { label: 'Nama', key: 'program_studi', sortable: true },
        { label: 'Jenjang', key: 'lkps_program', sortable: true },
        { label: 'Aksi', key: 'action' },
      ],
      departments: [],
      pagination: {
        amountOptions: [5, 10, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1,
      },
      searchKeyword: '',
      timeoutDebounce: null,

      // loadings
      fetchLoading: true,
      newDepartmentLoading: false,
      editDepartmentLoading: false,

      // validation
      required,
    }
  },
  watch: {
    'pagination.currentPage': function () {
      this.fetchDepartments()
    },
    'pagination.selectedAmount': function () {
      this.fetchDepartments()
    },
    searchKeyword(keyword) {
      if (keyword.length <= 0 || keyword.length >= 3) {
        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => this.fetchDepartments(keyword), 300)
      }
    },
  },
  created() {
    this.fetchDepartments()
  },
  methods: {

    fetchDepartments(keyword = '') {
      this.fetchLoading = true

      useJwt.http.get('department', {
        params: {
          page: this.pagination.currentPage - 1,
          size: this.pagination.selectedAmount,
          program_studi: keyword,
        },
      })
        .then(response => {
          this.departments = response.data.departments.map((department, idx) => ({
            id: department.id,
            number: idx + 1,
            program_studi: department.program_studi,
            lkps_program: `${department.lkps_program.jenis_program} (${department.lkps_program.short_name})`,
            lkps_program_id: department.lkps_program.id,
            action: '',
          }))

          this.pagination.totalItems = response.data.total_items
        })
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.fetchLoading = false)
    },
    onSubmit() {
      this.$refs.newDepartmentForm.validate().then(success => {
        if (success) {
          this.newDepartmentLoading = true

          useJwt.http.post('department', this.form.add)
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })

              this.form.add.program_studi = ''
              this.form.add.lkps_program_id = 1

              this.$bvModal.hide('new-data')

              this.fetchDepartments()
            })
            .catch(() => this.$alert({ title: 'Gagal menambah department.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.newDepartmentLoading = false)
        }
      })
    },
    onUpdate() {
      this.$refs.editDepartmentForm.validate().then(success => {
        if (success) {
          this.editDepartmentLoading = true

          useJwt.http.put(`department/${this.form.edit.id}`, this.form.edit)
            .then(response => {
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
              this.fetchDepartments()
              this.$bvModal.hide('edit-data')
            })
            .catch(() => this.$alert({ title: 'Gagal mengedit department.', variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.editDepartmentLoading = false)
        }
      })
    },
    prepareUpdate(data) {
      const { id, program_studi, lkps_program_id } = data.item
      this.form.edit.id = id
      this.form.edit.program_studi = program_studi
      this.form.edit.lkps_program_id = lkps_program_id
    },
    onDelete() {
      this.fetchLoading = true

      useJwt.http.delete(`department/${this.form.deleteId}/0`)
        .then(response => {
          this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
          this.fetchDepartments()
        })
        .catch(() => this.$alert({ title: 'Gagal menghapus department.', variant: 'danger', icon: 'XIcon' }))
        .finally(() => this.fetchLoading = false)
    },
    prepareDelete(data) {
      const { id } = data.item
      this.form.deleteId = id
    },
  },
}
</script>
<style>
  table#table-transition-gjmf .flip-list-move {
    transition: transform 0.4s;
  }
</style>
